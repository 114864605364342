
import './App.css';
import MainRoutes from './routes';





function App() {
  return (<div>
    <MainRoutes/>
    
    </div>
  );
}

export default App;
